































































import {Component, Vue} from 'vue-property-decorator'
import NavbarMenu from '@/components/NavbarMenu.vue'
import AppFooter from '@/components/AppFooter.vue'
import {MetaInfo} from 'vue-meta'
import {ExplorerCollection} from '@/model/collection/ExplorerCollection'
import ExplorerCard from '@/components/cards/ExplorerCard.vue'

@Component({
  components: {AppFooter, NavbarMenu, ExplorerCard},
})
export default class ExplorersView extends Vue {
  collection = new ExplorerCollection()

  async created() {
    this.applyCollection()
    await this.populateResources()
  }

  applyCollection() {
    this.collection.orderBy = 'order'
    this.collection.asc = true
    this.collection.perPage = 10
    this.collection.requestName = 'loadExplorers'
  }

  async populateResources() {
    await this.$await.run('loadExplorers', () => this.collection.expand())
  }

  metaInfo(): MetaInfo {
    return {
      title: this.$translate('metadata.title.explorers'),
    }
  }
}
